import React from 'react'
import { Link } from "gatsby"
import './styles.scss'

const Success = () => {
  return(
    <div>
      <h1>hello, success page</h1>
      <Link to="/">back to home</Link>
    </div>
  )
}

export default Success
